import React from "react"
import {Routes, Route, Navigate} from "react-router-dom"

import InnerContent from "./components/InnerContent"
import Dashboard from "./components/Dashboard"
import Tabs from "./components/Tabs"
import Settings from "./components/Settings"
import Login from "./components/Signin"
import Users from "./components/Users"
import SingleUser from "./components/SingleUser"
import NewUser from "./components/NewUser"
import DynamicForm from "./components/DynamicForm"

import Tab1 from "./components/Tab1"
import Tab2 from "./components/Tab2"
import Tab3 from "./components/Tab3"

import ProtectedRoutes from "./components/ProtectedRoutes"
import PublicRoutes from "./components/PublicRoutes"
import PermissionDenied from "./components/PermissionDenied"
import DataGridOrders from "./components/DataGridOrders"
import DataGridBalance from "./components/DataGridBalance"
import DataGridANSRec from "./components/DataGridANSRec"
import DataGridInvTrans from "./components/DataGridInvenTras"
import OrderReportDate from "./components/OrderReportDate"
import OrderReportCarrier from "./components/OrderReportCarrier"
import ReportInvoiceDetails from "./components/ReportInvoiceDetails"
import AppointmentDetails from "./components/AppointmentDetails"
import CreateUser from "./components/User/CreateUser"
import UserTabs from "./components/User/UserTabs"
import Permissions from "./components/User/Permissions"
import InvTransaction from "./components/InvTransaction"
import InvTransactionChart from "./components/InvTransactionChart"
import Topnav from "./components/Topnav"
import ContainerReport from "./components/ContainerReport"
import BillOfLandingReport from "./components/BillOfLandingReport"
import SignPDFDoc from "./components/SignPDFDoc"

import SignedPDF from "./components/SignedPDF"
import BOLUploadFile from "./components/BOLUploadFile"

const MainRoutes = () => (
	<Routes>
		{/** Protected Routes */}
		{/** Wrap all Route under ProtectedRoutes element */}
		<Route path="/" >
			<Route path="/" element={<InnerContent />}>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path="dashboard" element={<Dashboard />} />
                <Route path="orders" element={<DataGridOrders />} />
				<Route path="balance" element={<DataGridBalance />} />

				<Route path="reportOrderDate" element={<OrderReportDate />} />
				<Route path="reportOrderCarrier" element={<OrderReportCarrier />} />

				<Route path="reportContainerDetails" element={<ContainerReport />} />
				<Route path="bOLUploadFile" element={<BOLUploadFile />} />
				<Route path="reportBillOfLanding" element={<BillOfLandingReport />} />
				<Route path="reportSignPDFDoc" element={<SignPDFDoc />} />
				<Route path="reportInvoiceDetails" element={<ReportInvoiceDetails />} />
				<Route path="appointmentDetails" element={<AppointmentDetails />} />
				
				<Route path="createUser" element={<CreateUser/>} />
				<Route path="userPermissions" element={<Permissions/>} />

				<Route path="userTabs" element={<UserTabs/>} />

				<Route path="ansRec" element={<DataGridANSRec />} />
				<Route path="invnTrans" element={<InvTransactionChart />} />
				<Route path="tabs" element={<Tabs props={{userName: ""}} />}>
					<Route path="/tabs" element={<Navigate replace to="tab1" />} />
					<Route path="tab1" element={<Tab1 />} />
					<Route path="tab2" element={<ProtectedRoutes roleRequired="USER" />}>
						<Route path="/tabs/tab2" element={<Tab2 />} />
					</Route>
					<Route path="tab3" element={<Tab3 />} />
				</Route>
				<Route path="settings" element={<Settings />} />
				<Route path="dynamic-form" element={<DynamicForm />} />
               
				<Route
					path="users"
					element={<Users extraItem="test extra item from router" />}
				/>
				<Route path="users/:userId" element={<SingleUser />} />
				<Route path="users/new" element={<NewUser />} />
				<Route path="topnav" element={<Topnav />} />
			</Route>
		</Route>

		{/** Public Routes */}
		{/** Wrap all Route under PublicRoutes element */}
		{/* <Route path="/" element={<PublicRoutes />}>
			<Route path="/login" element={<Login />} />
		</Route> */}

        

		{/** Permission denied route */}
		<Route path="/denied" element={<PermissionDenied />} />
	</Routes>
)

export default MainRoutes
