import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import Signin from './components/Signin';
import { BrowserRouter } from 'react-router-dom';
import store from './ducks/redux/store'
import App from './App';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
   <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
    <App />
  </Provider>
    </BrowserRouter>
  </React.StrictMode>
);


