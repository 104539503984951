import React from "react"

const Settings = () => {
	return (
		<div className="settings">
			<h1>Welcome to settings page</h1>
			<></>
		</div>
	)
}

export default Settings
