import React from "react"

const PermissionDenied = () => {
	return (
		<div className="tab1">
			<h2>Permission denied!</h2>
		</div>
	)
}

export default PermissionDenied
