
import React, { Component, useEffect } from 'react'
import "./createUser.css"
import { Table } from 'react-bootstrap'
import { url } from 'inspector'
import { useNavigate } from 'react-router-dom'
import * as  CryptoJS from 'crypto-js';



export default function CreateUser() {
    const [userData, setData] = React.useState([])
    const [createuserData, setcreateuserData] = React.useState([])

    const key = CryptoJS.enc.Utf8.parse('bwplAPVp1OH7Xzyr');
    const iv = CryptoJS.enc.Utf8.parse('bwplAPVp1OH7Xzyr');

    const [fname, setfName] = React.useState("")
    const [lName, setlName] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [iD, setID] = React.useState("")


    const [isReset, setIsReset] = React.useState(false)
    const [userMsg, setUserMsg] = React.useState("")

    const [msgCss, setmsgCss] = React.useState("")

    const [pwdClass, setpwdClass] = React.useState(false)
    const [resetButton, setresetButton] = React.useState("hidden")
    const [pwd, setPwd] = React.useState("")
    const [confirmPwd, setconfirmPwd] = React.useState("")


    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlGetData: any = "https://igdapps.igdistribution.net/igdcpapi/api/Admin/GetCreateUsersPgData"

    const apiUrlCreateData: any = "https://igdapps.igdistribution.net/igdcpapi/api/Admin/AddUserDtls"
    const apiUrlUpdateData: any = "https://igdapps.igdistribution.net/igdcpapi/api/Admin/UpdateUserDtls"

    const apiUrlReset: any = "https://igdapps.igdistribution.net/igdcpapi/api/Admin/ResetUserPswd"

    const navigation = useNavigate()

    useEffect(() => {
        try {

            const data = {}
            getuserData(data)

        }
        catch (e) {
            //log error
        }
    },
        []);




    async function getuserData(param: any) {
        const responseBal = await getUser(param
        )

        if (responseBal) {
            setData(responseBal.Payload.Table1)
           // console.log(userData)
        }
    }

    async function createUserData(param: any) {
        const responseUserCreate = await createUser(param
        )

        if (responseUserCreate) {
            if (responseUserCreate?.status == 400) {
                setUserMsg("something went wrong")
                setmsgCss("loginMsg")
            }
            else if (responseUserCreate?.status == 401) {
                navigation("/login")
            }
            else {
                setcreateuserData(responseUserCreate)
                if (responseUserCreate?.Status == 0) {
                    //set error msg
                    setUserMsg(responseUserCreate.Message)
                    setmsgCss("loginMsg")
                }
                else {
                    setUserMsg(responseUserCreate.Message)
                    setmsgCss("loginSuccMsg")
                    if (responseUserCreate.Message != "") {
                        getuserData({})
                    }

                }
            }

        }
    }

    async function updateUserData(param: any) {
        const responseUserCreate = await updateUser(param
        )

        if (responseUserCreate) {
            if (responseUserCreate?.status == 400) {
                setUserMsg("something went wrong")
                setmsgCss("loginMsg")
            }
            else if (responseUserCreate?.status == 400) {
                navigation("/login")
            }
            else {
                setcreateuserData(responseUserCreate)
                if (responseUserCreate?.Status == 0) {
                    //set error msg
                    setUserMsg(responseUserCreate.Message)
                    setmsgCss("loginMsg")
                }
                else {
                    setUserMsg(responseUserCreate.Message)
                    setmsgCss("loginSuccMsg")
                    getuserData({})
                }
            }

        }
    }

    async function resetUser(param: any) {
        //set reset field
        setIsReset(true)
        setpwdClass(false)

    }
    async function createUserButton(param: any) {
        //set reset field
        clearData()
        setIsReset(false)
    }

    async function resetUserPwd(param: any) {
        //set reset field
        if (pwd != "") {
            setIsReset(true)
            const encryptedPwd = CryptoJS.AES.encrypt(pwd, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const params = {
                "uid": iD,
                "newPassword": encryptedPwd.toString()
            }
            const responseReset = await resetPwd(params)

            if (responseReset) {
                if (responseReset?.status == 400) {
                    setUserMsg("something went wrong")
                    setmsgCss("loginMsg")
                }
                else {
                    setcreateuserData(responseReset)
                    if (responseReset?.Status == 0) {
                        //set error msg
                        setUserMsg(responseReset.Message)
                        setmsgCss("loginMsg")
                    }
                    else {
                        setUserMsg(responseReset.Message)
                        setmsgCss("loginSuccMsg")
                        getuserData({})
                    }
                }

            }
        }
        else {
            setUserMsg("Enter password")
        }
    }

    async function getUser(credentials: any) {

        return fetch(apiUrlGetData, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                navigation("/login")
            })
    }

    async function createUser(credentials: any) {
        return fetch(apiUrlCreateData, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                setmsgCss("loginMsg")
                setUserMsg("Something went wrong, please try again")
                console.log("Failed")
            })
    }

    async function updateUser(credentials: any) {
        return fetch(apiUrlUpdateData, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                setmsgCss("loginMsg")
                setUserMsg("Something went wrong, please try again")
                console.log("Failed")
            })
    }

    async function resetPwd(credentials: any) {
        return fetch(apiUrlReset, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(credentials),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                navigation("/login")
            })
    }
    const submitData = (e: any) => {
        e.preventDefault();
        if (iD) {

            const userDataValues =
            {
                "Uid": iD,
                "ClientId": "2",
                "FirstName": fname,
                "LastName": lName,
                "PhoneNumber": phone,
                "Email": email,
                "Password": "",
                "ConfirmPassword": ""
            }

            const updateUserResponse = updateUserData(userDataValues)

            // if (updateUserResponse) {
            //     console.log("createUserResponse", updateUserResponse)
            // }
        }
        else {
            const encryptedPwd = CryptoJS.AES.encrypt(pwd, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const encryptedConfirmPwd = CryptoJS.AES.encrypt(pwd, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });

            const userDataValues =
            {
                "ClientId": "2",
                "FirstName": fname,
                "LastName": lName,
                "PhoneNumber": phone,
                "Email": email,
                "Password": encryptedPwd.toString(),
                "ConfirmPassword": encryptedConfirmPwd.toString()
            }

            const createUserResponse = createUserData(userDataValues)

            // if (createUserResponse) {
            //     console.log("createUserResponse", createUserResponse)
            // }
        }
    }

    const clearData = () => {
        setfName("")
        setlName("")
        setEmail("")
        setPhone("")
        setID("")
        setPwd("")
        setconfirmPwd("")
        setUserMsg("")
        setpwdClass(false)
    }

    const onEditUser = (e: any) => {
        if (e != undefined) {
            setresetButton("")
            setpwdClass(true)
            setfName(e?.FirstName)
            setlName(e?.LastName)
            setEmail(e.Email)
            setPhone(e.PhoneNumber)
            setID(e.Id)

            //post edit form

        }
    }


    return (
        <section className="vh-100 gradient-custom">
            <div className="container py-5 h-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-12 col-lg-9 col-xl-7 mt-8">
                        <div className='mb-8  text-center pageHeading'>App Settings - User</div>
                        <div className="card shadow-2-strong card-registration" style={{ borderRadius: 15 }}>
                            {!isReset ? (
                                <>
                                    <div className="card-body p-4 p-md-5">
                                        <h5 className="mb-4 font-bold  pb-md-0 mb-md-5">Create/Edit User</h5>
                                        <form onSubmit={submitData}>
                                            <div className="row ">
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="firstName">First Name</label>
                                                        <input type="text" id="firstName"
                                                            autoFocus
                                                            required
                                                            value={fname}
                                                            onChange={(e) => setfName(e.target.value)}
                                                            className="form-control form-control-lg" />
                                                    </div>

                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="lastName">Last Name</label>
                                                        <input type="text" id="lastName"
                                                            value={lName}
                                                            required
                                                            onChange={(e) => setlName(e.target.value)}
                                                            className="form-control form-control-lg" />

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 mb-4  ">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="emailAddress">Email</label>
                                                        <input type="email"
                                                            required
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)} id="emailAddress" className="form-control form-control-lg" />
                                                    </div>

                                                </div>
                                                <div className="col-md-6 mb-4  ">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="phoneNumber">Phone Number</label>
                                                        <input type="tel"
                                                            required
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)} id="phoneNumber" className="form-control form-control-lg" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        <input type="password"
                                                            value={pwd}
                                                            required
                                                            disabled={pwdClass}
                                                            onChange={(e) => setPwd(e.target.value)} id="password" className={"form-control form-control-lg"} />

                                                    </div>

                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div className="form-outline">
                                                        <label className="form-label" htmlFor="confirmpassword">Confirm password</label>
                                                        <input type="password"
                                                            value={confirmPwd}
                                                            disabled={pwdClass}
                                                            required
                                                            onChange={(e) => setconfirmPwd(e.target.value)} id="confirmpassword" className="form-control form-control-lg" />

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="mt-2 pt-2">
                                                <input className="btn btn-primary " type="submit" value="Submit" />
                                                <input className="btn btn-primary ml-2" type="button" onClick={clearData} value="Clear" />
                                                {pwdClass &&
                                                    (
                                                        <input className="btn btn-primary ml-2" type="button" onClick={resetUser} value="Reset Password" />
                                                    )
                                                }
                                                <p className={msgCss}>{userMsg}</p>
                                            </div>

                                        </form>
                                    </div></>
                            ) : (
                                <div className="card-body p-4 p-md-5">
                                    <h5 className="mb-8 font-bold  pb-md-0 mb-md-5">Reset Password</h5>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-outline">
                                                <label className="form-label" htmlFor="password">Enter New Password</label>
                                                <input type="password"
                                                    value={pwd}
                                                    required
                                                    disabled={pwdClass}
                                                    onChange={(e) => setPwd(e.target.value)} id="password" className={"form-control form-control-lg"} />

                                            </div>

                                        </div>

                                    </div>

                                    <div className="mt-2 pt-2">

                                        <input className="btn btn-primary ml-2" type="button" onClick={resetUserPwd} value="Reset" />
                                        <input className="btn btn-primary ml-2" type="button" onClick={createUserButton} value="Back" />
                                        <p className={msgCss}>{userMsg}</p>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
                {!isReset && (
                    <div>
                        <h5 className='font-bold'>User List</h5>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th className='hidden'>UserId</th>
                                    <th>User Name</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData?.map((s: any) => {
                                    return (
                                        <tr className='clickable' onClick={() => onEditUser(onEditUser(s))}>
                                            <td className='hidden'>{s.Id}</td>
                                            <td>{s.UserName}</td>
                                            <td>{s.FirstName}</td>
                                            <td>{s.LastName}</td>
                                            <td>{s.PhoneNumber}</td>
                                            <td>{s.Email}</td>

                                        </tr>)
                                }
                                )
                                }

                            </tbody>
                        </Table></div>
                )}
            </div>



        </section>

    )

}