import React, { useEffect } from 'react';
// import { DataGridPremium, GridRowsProp, GridToolbar } from '@mui/x-data-grid-premium';
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
    GridColDef,
    GridRowsProp,    
    GridToolbar
  } from '@mui/x-data-grid-premium';
import { useMediaQuery } from '@mui/material';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function DataGridANSRec() {

    const [status, setStatus] = React.useState('All');
    const [statusData, setStatusData] = React.useState([])

    const isMobile = useMediaQuery('(max-width: 450px)');

    const [ansRecData, setansRecData] = React.useState([])
    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")
    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlANSRec: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/getDashData_ASNReceipts"


    async function getAnsRecApiData() {
        const responseAnsRec = await getDashData_ansRec(
            {
                "receiptType":"all",
                "receiptStatus":"all",
                "fromDate":"2023-01-01",
                "toDate":"2024-12-31",
                "custCode": currCustomer,
                "whseCode": currWHS
            }
            )
        if (responseAnsRec) {
            setansRecData(responseAnsRec.Payload)
        }
    }

    async function getDashData_ansRec(params: any) {
        return fetch(apiUrlANSRec, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
            })
    }

    useEffect(() => {
        try {
            getAnsRecApiData()
        }
        catch (e) {
            //log error
        }
    },
        []);

    const columns : GridColDef[] = [
       { field: 'ASN_ReceiptNo', headerClassName: "headingbg-color", headerName: 'ASN ReceiptNo', width: 150 },
        { field: 'ReceiptType', headerClassName: "headingbg-color", headerName: 'Receipt Type', width: 150 },
        { field: 'ReceiptDate', headerClassName: "headingbg-color", headerName: 'Receipt Date', width: 200 },

        { field: 'totExpectedQty', headerClassName: "headingbg-color", headerName: 'Total Expected Qty', width: 200 },
        { field: 'totReceivedQty', headerClassName: "headingbg-color", headerName: 'Total Received Qty', width: 200 },
        { field: 'totDamagedQty', headerClassName: "headingbg-color", headerName: 'Total Damaged Qty', width: 200 },

        { field: 'ReceiptStatus', headerClassName: "headingbg-color", headerName: 'Receipt Status', width: 300 },
        { field: 'ExpectedArrivalDate', headerClassName: "headingbg-color", headerName: 'Expected Arrival Date', width: 200 },

        { field: 'WarehouseReference', headerClassName: "headingbg-color", headerName: 'Warehouse Reference', width: 200 },
        { field: 'DateCreated', headerClassName: "headingbg-color", headerName: 'Date Created', width: 200 },
        

    ]

    const rows: GridRowsProp = ansRecData.map((c: any) => ({
        Owner: c.Owner,
        WHSEID: c.WHSEID,
        ASN_ReceiptNo: c.ASN_ReceiptNo,
        ReceiptType: c.ReceiptType,
        ReceiptDate: c.ReceiptDate,
        totExpectedQty : c.totExpectedQty,
        totReceivedQty : c.totReceivedQty,
        totDamagedQty : c.totDamagedQty,
        ReceiptStatus: c.ReceiptStatus,
        ExpectedArrivalDate: c.ExpectedArrivalDate,
        WarehouseReference: c.WarehouseReference,
        DateCreated: c.DateCreated,
       
    }));


    return (
    <div style={{ height: "95vh" }}>
        <div className='ml-2' style={{ height: 550, width: '100%', }}>
           <div className='mt-8 mb-6 text-center pageHeading'>ASN Receipts Details </div>
            <div className='pb-2' style={{ width: '30%' }}>

            </div>
            {/* <p className='ml-2 mb-2'>
            <h6 className='colordivSumHead'> ASN/Receipt details:</h6>
            </p> */}
            <div style={{ height: 400, width: '100%', padding: '0 5%' }}>
            <DataGridPremium
              slots={{ toolbar: GridToolbar }} 
              slotProps={{
                toolbar: {
                    showQuickFilter: true,
                }
            }}
              getRowId={() => Math.floor(Math.random() * 100000000)}
              columns={columns}
              rows={rows} 
            //   style={{alignItems: 'center'}}                      
              ></DataGridPremium> 
              </div>          

        </div>
        </div>
    );
}
