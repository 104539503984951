import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/Sidebar';
import MainRoutes from './Routes';
import Topnav  from './components/Topnav';
import { LicenseInfo } from '@mui/x-license';
import { Height } from '@mui/icons-material';
import Footer from './components/Footer';

LicenseInfo.setLicenseKey('6e590720d0b3b5bfcff3012cf6975310Tz04Nzc2NixFPTE3NDM3OTI4NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function App() {
  return (
    <div style={{height: '100%',width:'100%' }} className='dashboard-color' > 
     {/* <Sidebar/>     */}
     <Topnav></Topnav>
       {/* <Sidebar></Sidebar> */}
    <MainRoutes/>
    <Footer/>
    </div>
  );
}

export default App;
