import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DataGridBalance from './DataGridBalance';

import OrderSummaryChart from './OrderChart';
import ASNSummaryChart from './ASNCharts';
import { useNavigate } from 'react-router-dom';

import { Grid, MenuItem } from '@mui/material';
import NoDashboard from '../NoDashboard';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();


  
  //console.log(localStorage.getItem("currentOwnerName"))
  // console.log(localStorage.getItem("currentOwner"))
  // console.log(localStorage.getItem("currentWHS"))

  // console.log(localStorage.getItem("currentOwnerName"))
  // console.log(localStorage.getItem("currentWHSName"))

  const currentUserID: any = localStorage.getItem("userId")

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  

  React.useEffect(() => {
    if (currentUserID) {
      const dashboardTab = localStorage.getItem("isDashboard")
      
      if (dashboardTab == "true") {
      //  console.log("dashboardTab load")
        //navigate("/topnav")
          // getPageMasterDataByUser(currentUserID, tokenValue)
          // getCurrentSettingsByUser(currentUserID, tokenValue)
      }
      else {
      //  console.log("else tadnav useeffect")
      /// navigate("/dashboard")
     // window.location.reload();
      }
    }
  }, [])

  const dashboardTab = localStorage.getItem("isDashboard")
  const orderTab = localStorage.getItem("isOrderTab")
  const asnTab = localStorage.getItem("isAsnTab")
  const inventoryTab = localStorage.getItem("isInventoryTab")
  const repackTab = localStorage.getItem("isRepackTab")
  const returnTab = localStorage.getItem("isReturnTab")

  return (
    <div>
      {
        dashboardTab == "true" ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'ButtonText' }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                    indicatorColor='primary'
                    textColor='secondary'
                    variant='scrollable'
                  >
                    {orderTab === "true" ? (
                      <Tab label="Orders" {...a11yProps(0)} />
                    ) : (
                      <Tab disabled label="Orders" {...a11yProps(0)} />
                    )}
                    {asnTab === "true" ? (
                      <Tab label="ASN" {...a11yProps(1)} />
                    ) : (
                      <Tab disabled label="ASN" {...a11yProps(1)} />
                    )}
                    {inventoryTab === "true" ? (
                      <Tab label="Inventory" {...a11yProps(2)} />
                    ) : (
                      <Tab disabled label="Inventory" {...a11yProps(2)} />
                    )
                    }
                    {/* {repackTab === "true" ? (
                      <Tab label="Repacks" {...a11yProps(3)} />
                    ) : (
                      <Tab disabled label="Repacks" {...a11yProps(3)} />
                    )
                    }
                    {returnTab === "true" ? (
                      <Tab label="Returns" {...a11yProps(4)} />
                    ) : (
                      <Tab disabled label="Returns" {...a11yProps(4)} />
                    )
                    } */}
                  </Tabs>
                </Grid>

              </Grid>




            </Box>

            {orderTab === "true" && (
              <CustomTabPanel value={value} index={0} >
                <OrderSummaryChart></OrderSummaryChart>
              </CustomTabPanel>)}
            {asnTab === "true" && (
              <CustomTabPanel value={value} index={1}>
                <ASNSummaryChart></ASNSummaryChart>
              </CustomTabPanel>)}
            {inventoryTab === "true" && (
              <CustomTabPanel value={value} index={2}>
                <DataGridBalance></DataGridBalance>
              </CustomTabPanel>)}



          </Box>

        ) : (
          <NoDashboard></NoDashboard>
        )
      }
    </div>

  );
}


