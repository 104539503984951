import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {
    DataGridPremium,
    GridColDef,
    useGridApiContext,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridRowParams,
    GridRowsProp,
    GridRowId,
    GridCallbackDetails,
    DataGridPremiumProps,
    GridToolbar,
} from '@mui/x-data-grid-premium';

import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Height, InsertInvitationSharp } from '@mui/icons-material';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import InvTransactionChart from './InvTransactionChart';
import { useNavigate } from 'react-router-dom';

interface OrderSummaryDetailsProps {
    activityType: string | undefined;
    fromDate: string
    toDate: string
}


function DetailPanelContent({ row: rowProp }: { row: any }) {
    const apiRef = useGridApiContext();

    const tokenValue: any = localStorage.getItem("accessToken")
    const navigate = useNavigate();
    const apiUrlItemsOrder: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/GetItemDetails"
   
    const [open, setOpen] = React.useState(false);
  
    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")

    const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([])
    const [orderItemsDataRows, setorderItemsDataRows] = React.useState([])
    const [width, setWidth] = React.useState(() => {
        const dimensions = apiRef.current.getRootDimensions();
        return dimensions.viewportInnerSize.width;
    });

    const handleViewportInnerSizeChange = React.useCallback(() => {
        const dimensions = apiRef.current.getRootDimensions();
        setWidth(dimensions.viewportInnerSize.width);
    }, [apiRef]);


    React.useEffect(() => {
        createMasterData(rowProp.ItemNo)
        return apiRef.current.subscribeEvent(
            'viewportInnerSizeChange',
            handleViewportInnerSizeChange,

        );
    }, [apiRef, handleViewportInnerSizeChange]);

    async function getOrderItemsApiData(params: any) {
        const responseOrdersItems = await getDashData_ordersItems(
            {
                "itemNo": params,
                "custCode": currCustomer,
                "whseCode": currWHS
            })
        if (responseOrdersItems) {
            setorderItemsDataDetails(responseOrdersItems?.Payload)
        }
    }

    async function getDashData_ordersItems(params: any) {
        return fetch(apiUrlItemsOrder, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    const createMasterData = (rowprops: any) => {
        let dataItems: Array<any> = []
        getOrderItemsApiData(rowProp.ItemNo)


    }   
  
    const handleClose = () => {
        setOpen(false);
    };
   

    const columnsItem: GridColDef[] = [
        { field: 'Item',headerClassName:"headingbg-color", headerName: 'Item' },
        { field: 'Description',headerClassName:"headingbg-color", headerName: 'Description' },
        { field: 'Pack',headerClassName:"headingbg-color", headerName: 'Pack' },
        { field: 'GrossWeight',headerClassName:"headingbg-color", headerName: 'Gross Weight' },
      
        { field: 'NetWeight',headerClassName:"headingbg-color", headerName: 'Net Weight' },
        { field: 'Cube',headerClassName:"headingbg-color", headerName: 'Cube' },
        { field: 'Class',headerClassName:"headingbg-color", headerName: 'Class' },
        { field: 'ItemGroup1',headerClassName:"headingbg-color", headerName: 'Item Group1' },
        { field: 'PICKCODE',headerClassName:"headingbg-color", headerName: 'PICK CODE' },
        { field: 'CartonGroup',headerClassName:"headingbg-color", headerName: 'Carton Group' },
    
        { field: 'PUTCODE',headerClassName:"headingbg-color", headerName: 'PUT CODE' },
        { field: 'PutAwayLocation',headerClassName:"headingbg-color", headerName: 'PutAway Location' },
        { field: 'PutAwayZone',headerClassName:"headingbg-color", headerName: 'PutAway Zone' },
        { field: 'DateItemLastCounted',headerClassName:"headingbg-color", headerName: 'Date Item Last Counted' },
        { field: 'InboundQCLocation',headerClassName:"headingbg-color", headerName: 'Inbound QC Location' },
        { field: 'RotateBy',headerClassName:"headingbg-color", headerName: 'Rotate By' },
    
        { field: 'RFDefaultReceivingPack',headerClassName:"headingbg-color", headerName: 'RF Default Receiving Pack' },
        { field: 'RFDefaultReceivingUOM',headerClassName:"headingbg-color", headerName: 'RF Default Receiving UOM' },
        { field: 'ReturnLocation',headerClassName:"headingbg-color", headerName: 'Return Location' },
        { field: 'InboundQCLoc',headerClassName:"headingbg-color", headerName: 'Inbound QC Loc' },
        { field: 'ReceiptValidation',headerClassName:"headingbg-color", headerName: 'Receipt Validation' },
        { field: 'OutboundQCLoc',headerClassName:"headingbg-color", headerName: 'Outbound QC Loc' },
    
        
      ];
    
      const rowItems : GridRowsProp = orderItemsDataDetails.map((c: any, index) => ({
        id : index,
        Owner :c.Owner,
        Warehouse:c.Warehouse,
        Item:c.Item,
        Description : c.Description,
        Pack:c.Pack,
        GrossWeight:c.GrossWeight,
        NetWeight:c.NetWeight,
        Cube:c.Cube,
        Class:c.Class,
        ItemGroup1:c.ItemGroup1,
        PICKCODE:c.PICKCODE,
        CartonGroup:c.CartonGroup,
        PUTCODE:c.PUTCODE,
        PutAwayLocation:c.PutAwayLocation,
        PutAwayZone:c.PutAwayZone,
        DateItemLastCounted:c.DateItemLastCounted,
        InboundQCLocation:c.InboundQCLocation,
        RotateBy:c.RotateBy,
        RFDefaultReceivingPack:c.RFDefaultReceivingPack,
        RFDefaultReceivingUOM:c.RFDefaultReceivingUOM,
        ReturnLocation:c.ReturnLocation,
        InboundQCLoc:c.InboundQCLoc,
        ReceiptValidation:c.ReceiptValidation,
        OutboundQCLoc:c.OutboundQCLoc
       }))  

  
    return (
        <><Stack
            sx={{
                py: 2,
                height: '100%',
                boxSizing: 'border-box',
                position: 'sticky',
                left: 0,
                width,
            }}
            direction="column"
        >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', height: '1000px', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6">{`Order #${rowProp.ItemNo}`}</Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                        <DataGridPremium
                            density="compact"
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                }
                            }}
                            rowSelection={true}                           
                            columns={columnsItem}
                            rows={rowItems}                           
                            sx={{ flex: 1 }}
                            hideFooter />
                    </Box>
                </Stack>
            </Paper>
        </Stack>         </>
    );
}

const columns = [
   { field: 'ActivityDate',headerClassName:"headingbg-color", headerName: 'Activity Date'},
    { field: 'Activity',headerClassName:"headingbg-color", headerName: 'Activity' },
    { field: 'ItemNo',headerClassName:"headingbg-color", headerName: 'Item No'},
    { field: 'Lot',headerClassName:"headingbg-color", headerName: 'Lot'},
    { field: 'ToLocation',headerClassName:"headingbg-color", headerName: 'To Location' },
    { field: 'ToLPN',headerClassName:"headingbg-color", headerName: 'To LPN' },
    { field: 'FromLocation',headerClassName:"headingbg-color", headerName: 'From Location' },
    { field: 'FromLPN',headerClassName:"headingbg-color", headerName: 'From LPN' },
    { field: 'Quantity',headerClassName:"headingbg-color", headerName: 'Quantity' },
    { field: 'GrossWeight',headerClassName:"headingbg-color", headerName: 'Gross Weight' },
    { field: 'NetWeight',headerClassName:"headingbg-color", headerName: 'Net Weight' },
    { field: 'TareWeight',headerClassName:"headingbg-color", headerName: 'Tare Weight' },
    { field: 'User',headerClassName:"headingbg-color", headerName: 'User' },
    
]

const InvTransaction: React.FC<OrderSummaryDetailsProps> = (props) => {
    const [orderData, setOrderData] = React.useState([])
    const [orderItemsData, setorderItemsData] = React.useState([])
    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 200, []);


    const [selectedFrom, setselectedFrom] = React.useState('');
    const [selectedTo, setselectedTo] = React.useState('');
    
    const navigate = useNavigate();

    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlOrder: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/getDashData_inventoryTrans"
    const apiUrlItemsOrder: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/GetItemDetails"

    React.useEffect(() => {
        try {
            getOrdersApiData("")
        }
        catch (e) {
            //log error
        }
    },
        [props]);


        const currCustomer = localStorage.getItem("currentOwner")
        const currWHS = localStorage.getItem("currentWHS")

    async function getOrdersApiData(params: any) {
        
        const responseOrders = await getDashData_orders(
            {
                "activityType": props.activityType,               
                "fromDate": props.fromDate,
                "toDate":  props.toDate,
                "custCode": currCustomer,
                "whseCode": currWHS

            })

        if (responseOrders) {
            setOrderData(responseOrders.Payload)
        }
    }

    async function getDashData_orders(params: any) {
        return fetch(apiUrlOrder, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }
       
    React.useEffect(() => {
        try {
            getOrdersApiData("")
        }
        catch (e) {
            //log error
        }
    },
        []);


        const rows: GridRowsProp = orderData?.map((c: any,index) => ({
            id : index,
            Owner: c.Owner,
            WHSEID: c.WHSEID,
            ActivityDate: c.ActivityDate,
            Activity: c.Activity,
            ItemNo: c.ItemNo,
            Lot: c.Lot,
            ToLocation: c.ToLocation,
            ToLPN: c.ToLPN,
            FromLocation: c.FromLocation,
            FromLPN: c.FromLPN,
            Quantity: c.Quantity,
            GrossWeight: c.GrossWeight,
            NetWeight: c.NetWeight,
            TareWeight: c.TareWeight,
            User: c.User
        }));

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[], details: GridCallbackDetails) => {
         
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );

        },
        [],
    );

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const [state, setState] = React.useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;

    
    const label =
    start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    

    return (
       
        
        <Box sx={{ width: '100%', height: '70vh', padding: '0 5%' }}>
               <div className='mb-4 mt-8 ml-2 colordivSumHead'>Inventory Transaction Details:</div>
               <div className='mb-4'></div>
                 
        
        <div className='mb-4'></div>
            <DataGridPremium
                columns={columns}
                rows={rows}
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    }
                }}
                pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                sx={{
                    '& .MuiDataGrid-detailPanel': {
                        overflow: 'visible',
                    },
                }}
            />
        </Box>
    );
}

export default InvTransaction
