import React, { useEffect, useState } from "react";
import {
  DataGridPremium,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function OrderReportDate() {
  const [status, setStatus] = React.useState("All");
  const [statusData, setStatusData] = React.useState([]);

  const [open, setOpen] = React.useState(true);

  const toggle = () => setOpen(!open);

  const [balanceData, setBalanceData] = React.useState([]);

  const currCustomer = localStorage.getItem("currentOwner");
  const currWHS = localStorage.getItem("currentWHS");
  const navigate = useNavigate();
  const tokenValue: any = localStorage.getItem("accessToken");
  const apiUrlBal: any =
    "https://igdapps.igdistribution.net/igdcpapi/api/user/getRprt_dateWiseShipmentDtls";

  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start: any, end: any) => {
    setState({ start, end });
    const labelStart = start.format("YYYY-MM-DD");
    const labelEnd = end.format("YYYY-MM-DD");

    getBalApiData(labelStart, labelEnd);
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");

  async function getBalApiData(frmDate: string, toDate: string) {
    const responseBal = await getDashData_balance({
      orderNo: "all",
      poNo: "all",
      shipTo: "all",
      billTo: "all",
      fromDate: frmDate,
      toDate: toDate,
      custCode: currCustomer,
      whseCode: currWHS,
    });
    if (responseBal) {
      setBalanceData(responseBal.Payload);
    }
  }

  async function getDashData_balance(params: any) {
    return fetch(apiUrlBal, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  useEffect(() => {
    try {
      const startdate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");

      getBalApiData(startdate, endDate);
    } catch (e) {
      //log error
    }
  }, []);

  const columns = [
    {
      field: "OrderNumber",
      headerClassName: "headingbg-color",
      headerName: "OrderNumber",
    },
    {
      field: "ShipDate",
      width: 120,
      headerClassName: "headingbg-color",
      headerName: "ShipDate",
    },
    {
      field: "CustPOnumber",
      headerClassName: "headingbg-color",
      headerName: "CustPOnumber",
      width: 200,
    },
    {
      field: "ShipTo",
      headerClassName: "headingbg-color",
      headerName: "ShipTo",
    },
    {
      field: "BillTo",
      headerClassName: "headingbg-color",
      headerName: "BillTo",
    },

    {
      field: "BLNumber",
      headerClassName: "headingbg-color",
      headerName: "BLNumber",
    },
    {
      field: "LoadNumber",
      headerClassName: "headingbg-color",
      headerName: "LoadNumber",
    },
    {
      field: "TRAILERKEY",
      headerClassName: "headingbg-color",
      headerName: "TRAILERKEY",
    },
    {
      field: "TrailerNumber",
      headerClassName: "headingbg-color",
      headerName: "TrailerNumber",
    },
    {
      field: "TrailerType",
      headerClassName: "headingbg-color",
      headerName: "TrailerType",
    },

    {
      field: "ItemNumber",
      headerClassName: "headingbg-color",
      headerName: "ItemNumber",
    },
    {
      field: "Description",
      width: 250,
      headerClassName: "headingbg-color",
      headerName: "Description",
    },
  ];

  const rows: GridRowsProp = balanceData.map((c: any, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    OrderNumber: c.OrderNumber,
    ShipDate: moment(c.ShipDate).format("YYYY-MM-DD"),
    CustPOnumber: c.CustPOnumber,
    ShipTo: c.ShipTo,
    BillTo: c.BillTo,
    BLNumber: c.BLNumber,
    LoadNumber: c.LoadNumber,
    TRAILERKEY: c.TRAILERKEY,
    TrailerNumber: c.TrailerNumber,
    TrailerType: c.TrailerType,
    ItemNumber: c.ItemNumber,
    Description: c.Description,
  }));

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <div className='ml-2' style={{ height: '110vh' }}>
      <div className="mt-4 mb-6 text-center pageHeading">
        Shipment Report Date wise
      </div>
      <div className="pb-2" style={{ width: "30%" }}></div>

      {/* <p className='ml-2  mb-2 colordivSumHead'>Shipment Report Date wise :</p> */}
      <div className="ml-2" style={{ paddingLeft: "5%" }}>
        <p className="colordivSumHead">Select a date to view details:</p>
        <DateRangePicker
          initialSettings={{
            startDate: start.toDate(),
            endDate: end.toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().endOf("month").toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          onCallback={handleCallback}
        >
          <div
            id="reportrange"
            className="mb-8 col-4"
            style={{
              // background: "#fff",
              cursor: "pointer",
              padding: "8px 10px",
              border: "1.5px solid #ccc",
              width: "220px",
              fontSize: "14px",
              borderRadius: "4px"
            }}
          >
            <i className="fa fa-calendar"></i>&nbsp;
            <span>{label}</span> <i className="fa fa-caret-down"></i>
          </div>
        </DateRangePicker>
      </div>
      {balanceData.length > 0 ? (
        <div style={{ height: 400, width: "100%", padding: "0 5%" }}>
          <DataGridPremium
            slots={{ toolbar: GridToolbar }}     
           
            columns={columns}
            rows={rows}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          ></DataGridPremium>
        </div>
      ) : (
        //     <DataGridPremium
        //     slots={{ noRowsOverlay: CustomNoRowsOverlay}}
        //     columns={[]}
        //     rows={[]}

        // ></DataGridPremium>
        <div className="pb-2" style={{ width: "50%", height: "400" }}>
          No Records found
        </div>
      )}
    </div>
  );
}
