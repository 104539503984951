import React, { useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import BasicTabs from './TabsPage';
import { Link, Navigate, useNavigate } from "react-router-dom";

const  Dashboard=() =>{
  
  const navigate = useNavigate();
  useEffect(() => {  
    if(!localStorage.getItem("fname"))      
    navigate("/login")    
  })
 
  return(
    <div>  
      
   <div className="mt-2 ml-8 ">   
     <h1 className='pl-8'></h1>
     <BasicTabs></BasicTabs> 
  </div>
  </div>
  )
}

export default Dashboard;
